export const getNowTime = (tzOffset) => {
  const now = new Date();

  const isDayLightSavingsTime = () => {
    const year = now.getFullYear();
    
    const dstStart = new Date(year, 2, 1);
    dstStart.setDate(1 + (7 - dstStart.getDay()) % 7 + 7);

    const dstEnd = new Date(year, 10, 1);
    dstEnd.setDate(1 + (7 - dstEnd.getDay()) % 7);

    return now >= dstStart && now < dstEnd;
  };

  const adjustedOffset = isDayLightSavingsTime() ? tzOffset + 60 : tzOffset;
  const localDate = new Date(now.getTime() + adjustedOffset * 60 * 1000);

  return localDate.toISOString();
};