import React, { useContext, useState, useEffect } from "react";
import MenuContext from "../../MenuContext";
import Modal from "react-bootstrap/Modal";
import "./itemModal.scss";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import { formatMenuItemExtras } from "../../utils/menu/FormatMenuItemExtras";
import ApplicationContext from "../../ApplicationContext";
import SpecialRequest from "../../tools/SpecialRequest";
import { CreateGuid } from "../../utils/authenticate/CreateGUID";
import axios from "axios";
import { APPLICATION, BASE_API, STORE_NAME_CODE } from "../../constants";
import { checkIfItemExistsInCart } from "../../utils/cart/CheckIfItemExistsInCart";
import { updateCartValues } from "../../utils/cart/UpdateCartValues";
import { toast } from "react-toastify";
import { FaWindowClose } from "react-icons/fa";
import Loading from "../../tools/Loading";
import { FaChevronCircleUp } from "react-icons/fa";
import { FaChevronCircleDown } from "react-icons/fa";

function ItemModal(props) {
  const menuContext = useContext(MenuContext);
  const appContext = useContext(ApplicationContext);
  const [sameNameItems, setSameNameItems] = useState([]);
  const [menuItemExtras, setMenuItemExtras] = useState();
  const [quantity, setQuantity] = useState(1);
  const [selectedSize, setSelectedSize] = useState();
  const [selectedExtras, setSelectedExtras] = useState([]);
  const [expandedSections, setExpandedSections] = useState({});
  var specialRequests = "";

  useEffect(() => {
    // Set all sections to open initially
    if (menuItemExtras) {
      const initialState = {};
      if (sameNameItems.length > 1) {
        initialState["sameNameItems"] = true;

        if (menuItemExtras.length === 1) {
          for (var b = 0; b < menuItemExtras.length; b++) {
            initialState[b] = true;
          }
        }
      } else {
        for (var a = 0; a < menuItemExtras.length; a++) {
          initialState[a] = true;

          if (menuItemExtras[a].code.specialty === 1) {
            return;
          }
        }
      }

      setExpandedSections(initialState);
    }
  }, [sameNameItems, menuItemExtras]);

  const toggleSection = (index) => {
    setExpandedSections((prevState) => {
      const isCurrentlyOpen = prevState[index];
      const isOpening = !isCurrentlyOpen;

      if (isOpening === true) {
        setTimeout(() => {
          for (let x = 0; x < selectedExtras.length; x++) {
            const id =
              `${selectedExtras[x].extraName}${selectedExtras[x].extraDescription}`.replace(
                /\s+/g,
                ""
              );
            const element = document.getElementById(id);

            if (element) {
              element.classList.add("Active");
            }
          }
        }, 0); // Delay to ensure DOM updates
      }

      return {
        ...prevState,
        [index]: isOpening,
      };
    });
  };

  const [itemFinalTotal, setItemFinalTotal] = useState(
    (Math.round(props.selectedItem.itemPrice * 100) / 100).toFixed(2)
  );
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    var sameNameItemsArray = [];
    for (var i = 0; i < menuContext.items.length; i++) {
      if (menuContext.items[i].itemName === props.selectedItem.itemName) {
        sameNameItemsArray.push(menuContext.items[i]);
      }
    }
    if (sameNameItemsArray.length > 1) {
      setSelectedSize(props.selectedItem);
    }
    const sortedArray = sameNameItemsArray.sort(
      (a, b) => a.itemPrice - b.itemPrice
    );
    setSameNameItems(sortedArray);
  }, []);

  useEffect(() => {
    if (props.selectedItem.itemOptions) {
      const itemExtraOptions = props.selectedItem.itemOptions.split("||");
      var menuItemExtrasArray = [];
      for (var a = 0; a < itemExtraOptions.length; a++) {
        for (var i = 0; i < menuContext.extras.length; i++) {
          if (menuContext.extras[i].extraType === itemExtraOptions[a]) {
            menuItemExtrasArray.push(menuContext.extras[i]);
          }
        }
      }
      var formattedMenuItemExtras = formatMenuItemExtras(menuItemExtrasArray);
    }
    if (formattedMenuItemExtras === undefined) {
      setMenuItemExtras([]);
    } else {
      const requiredExtras = formattedMenuItemExtras
        .filter(
          (item) =>
            item.extras.length === 1 &&
            item.code.maxQty === 1 &&
            item.code.required === 1
        )
        .map((item) => item.extras[0].options[0]);

      if (selectedExtras.length < 1 && requiredExtras.length > 0) {
        addDefaultExtras(requiredExtras);
      }
      setMenuItemExtras(formattedMenuItemExtras);
    }
  }, []);

  function addDefaultExtras(extraList) {
    setTimeout(() => {
      extraList.forEach((extra) => {
        const elementId = `${extra.extraName.replace(/\s/g, "")}${extra.extraDescription}`;
        const setActiveExtra = document.getElementById(elementId);

        if (!setActiveExtra.classList.contains("Active")) {
          if (setActiveExtra) {
            setActiveExtra.classList.add("Active");

            setSelectedExtras((prevExtras) => [...prevExtras, extra]);

            setItemFinalTotal((prevTotal) =>
              (
                parseFloat(prevTotal) + parseFloat(extra.extraPrice) * quantity
              ).toFixed(2)
            );
          }
        }
      });
    }, 100);
  }

  async function addQuantity() {
    var newQuantity = quantity + 1;
    setItemFinalTotal(
      (
        parseFloat(itemFinalTotal) + parseFloat(itemFinalTotal / quantity)
      ).toFixed(2)
    );
    setQuantity(newQuantity);
  }

  async function subtractQuantity() {
    if (quantity > 1) {
      var newQuantity = quantity - 1;
      setItemFinalTotal(
        (
          parseFloat(itemFinalTotal) - parseFloat(itemFinalTotal / quantity)
        ).toFixed(2)
      );
      setQuantity(newQuantity);
    }
  }

  async function selectSize(i) {
    setSelectedSize(i);
    setItemFinalTotal(
      parseFloat(Math.round(i.itemPrice * quantity * 100) / 100).toFixed(2)
    );

    const optionsWithActiveClass = document.querySelectorAll(".Active");
    optionsWithActiveClass.forEach((element) => {
      element.classList.remove("Active");
    });
    const extraOptionsWithActiveClass = document.querySelectorAll(".Active");
    extraOptionsWithActiveClass.forEach((element) => {
      element.classList.remove("Active");
    });
    setSelectedExtras([]);

    var alreadyActiveSize = document.querySelector(".size.Active");
    if (alreadyActiveSize) {
      alreadyActiveSize.classList.remove("Active");
    }
    var makeActiveSize = document.getElementById(`${i.itemSize}Size`);
    if (makeActiveSize) {
      makeActiveSize.classList.add("Active");
    }

    var menuItemExtrasArray = [];
    if (i.itemOptions) {
      const itemExtraOptions = i.itemOptions.split("||");
      for (var a = 0; a < itemExtraOptions.length; a++) {
        for (var x = 0; x < menuContext.extras.length; x++) {
          if (menuContext.extras[x].extraType === itemExtraOptions[a]) {
            menuItemExtrasArray.push(menuContext.extras[x]);
          }
        }
      }

      var formattedMenuItemExtras = formatMenuItemExtras(menuItemExtrasArray);
      setMenuItemExtras(formattedMenuItemExtras);
    } else {
      setMenuItemExtras([]);
    }

    const elements = document.getElementsByClassName("extraToppingPrice");

    const elementsArray = Array.from(elements);

    elementsArray.forEach((element) => {
      element.innerHTML = "";
    });
  }

  function selectToppings(topping) {
    if (topping.specialtyPizza === 1) {
      for (var r = 0; r < selectedExtras.length; r++) {
        if (
          selectedExtras[r].extraName !== topping.extraName &&
          selectedExtras[r].specialtyPizza === 1 &&
          selectedExtras[r].extraDescription === "Full"
        ) {
          toast.error(
            "You cannot have more than 1 specialty for a full pizza."
          );
          return;
        }
        if (
          selectedExtras[r].extraName !== topping.extraName &&
          selectedExtras[r].specialtyPizza === 1 &&
          (selectedExtras[r].extraDescription === "FirstHalf" ||
            selectedExtras[r].extraDescription === "SecondHalf") &&
          topping.extraDescription === "Full"
        ) {
          toast.error(
            "You cannot add a specialty for a full pizza when you have a half selected."
          );
          return;
        }
      }

      for (var e = 0; e < selectedExtras.length; e++) {
        if (
          selectedExtras[e].specialtyPizza === 1 &&
          selectedExtras[e].extraDescription === topping.extraDescription
        ) {
          if (topping.extraDescription === "Full") {
            toast.error(
              "You cannot have more than 1 selection for a full pizza."
            );
            return;
          } else {
            if (
              selectedExtras[e].specialtyPizza === 1 &&
              selectedExtras[e].extraDescription === topping.extraDescription &&
              selectedExtras[e].extraName !== topping.extraName
            ) {
              toast.error("You cannot have more than 1 selection per half.");
              return;
            }
          }
        }
      }
    }

    //check if that topping already has an active topping
    var alreadyActiveSameTopping = document.querySelector(
      `.${topping.extraName.replace(/\s/g, "")}.Active`
    );
    var addShowingPriceToExtra = document.getElementById(
      `${topping.extraName.replace(/\s/g, "")}_selected`
    );

    //if it does remove Active
    if (alreadyActiveSameTopping) {
      alreadyActiveSameTopping.classList.remove("Active");
    }

    //set topping clicked on to be the active topping
    var setActiveExtra = document.getElementById(
      `${topping.extraName.replace(/\s/g, "")}${topping.extraDescription}`
    );
    if (setActiveExtra) {
      if (alreadyActiveSameTopping !== setActiveExtra) {
        setActiveExtra.classList.add("Active");
      }
      addShowingPriceToExtra.innerHTML = `${topping.extraName} <span class="extraToppingPrice fs-6 fw-bold">$${topping.extraPrice}</span>`;
      addShowingPriceToExtra.style.display = "inline-block";
    }

    //add topping to selectedExtras
    if (selectedExtras.length < 1) {
      setSelectedExtras(selectedExtras.concat(topping));
      setItemFinalTotal(
        (
          parseFloat(itemFinalTotal) +
          parseFloat(topping.extraPrice) * quantity
        ).toFixed(2)
      );
    } else {
      var sameExtra = false;
      for (var m = 0; m < selectedExtras.length; m++) {
        if (
          //(if) it is the same exact extra
          selectedExtras[m].extraName === topping.extraName &&
          selectedExtras[m].extraDescription === topping.extraDescription
        ) {
          setItemFinalTotal(
            (
              parseFloat(itemFinalTotal) -
              parseFloat(selectedExtras[m].extraPrice) * quantity
            ).toFixed(2)
          );
          selectedExtras.splice(m, 1);
          sameExtra = true;

          addShowingPriceToExtra.innerHTML = `${topping.extraName}`;
          addShowingPriceToExtra.style.display = "inline-block";

          break;
        } else if (
          //(else if) it is the same name pizza extra not exactly the same
          selectedExtras[m].extraName === topping.extraName &&
          selectedExtras[m].extraDescription !== topping.extraDescription
        ) {
          setItemFinalTotal(
            (
              parseFloat(topping.extraPrice) * quantity +
              (parseFloat(itemFinalTotal) -
                parseFloat(selectedExtras[m].extraPrice) * quantity)
            ).toFixed(2)
          );
          selectedExtras.splice(m, 1);

          break;
        } else {
          //else its not the same extra at all
          setItemFinalTotal(
            (
              parseFloat(topping.extraPrice) * quantity +
              parseFloat(itemFinalTotal)
            ).toFixed(2)
          );
        }
      }
      if (sameExtra !== true) {
        setSelectedExtras(selectedExtras.concat(topping));
      }
    }
  }

  function selectExtra(extra) {
    //check if that topping already has an active extra of same type
    var alreadyActiveSameExtraType = document.querySelector(
      `.${extra.extraType.replace(/\s/g, "")}.Active`
    );
    //if it does remove Active
    if (alreadyActiveSameExtraType && extra.selectMultiple !== "true") {
      alreadyActiveSameExtraType.classList.remove("Active");
    }

    //set topping clicked on to be the active topping
    var setActiveExtra = document.getElementById(
      `${extra.extraName.replace(/\s/g, "")}${extra.extraDescription}`
    );
    if (setActiveExtra) {
      if (alreadyActiveSameExtraType !== setActiveExtra) {
        setActiveExtra.classList.add("Active");
      }
    }

    if (selectedExtras.length < 1) {
      setSelectedExtras(selectedExtras.concat(extra));
      setItemFinalTotal(
        parseFloat(
          (
            parseFloat(itemFinalTotal) +
            parseFloat(extra.extraPrice).toFixed(2) * quantity
          ).toFixed(2)
        )
      );
    } else {
      var add = true;
      for (var m = 0; m < selectedExtras.length; m++) {
        if (
          selectedExtras[m].extraCode === extra.extraCode &&
          extra.selectMultiple !== "true"
        ) {
          if (selectedExtras[m].extraName !== extra.extraName) {
            //if it is not the same extra
            setItemFinalTotal(
              (
                parseFloat(extra.extraPrice) * quantity +
                (parseFloat(itemFinalTotal) -
                  parseFloat(selectedExtras[m].extraPrice) * quantity)
              ).toFixed(2)
            );
            selectedExtras.splice(m, 1);
            add = true;
          } else {
            //else it is the same extra
            setItemFinalTotal(
              (
                parseFloat(itemFinalTotal) -
                parseFloat(selectedExtras[m].extraPrice) * quantity
              ).toFixed(2)
            );
            selectedExtras.splice(m, 1);
            add = false;
            return;
          }
        } else {
          if (selectedExtras[m].extraName !== extra.extraName) {
            setItemFinalTotal(
              (
                parseFloat(extra.extraPrice) * quantity +
                parseFloat(itemFinalTotal)
              ).toFixed(2)
            );
            add = true;
          } else {
            setItemFinalTotal(
              (
                parseFloat(itemFinalTotal) -
                parseFloat(extra.extraPrice) * quantity
              ).toFixed(2)
            );
            setActiveExtra.classList.remove("Active");
            selectedExtras.splice(m, 1);
            add = false;
            return;
          }
        }
      }
      if (add === true) {
        setSelectedExtras(selectedExtras.concat(extra));
      }
    }
  }

  function addExtra(extra) {
    var setActiveExtra = document.getElementById(
      `${extra.extraName.replace(/\s/g, "")}${extra.extraDescription}`
    );

    if (selectedExtras.length < 1) {
      setSelectedExtras(selectedExtras.concat(extra));
      setItemFinalTotal(
        parseFloat(itemFinalTotal) +
        parseFloat(extra.extraPrice).toFixed(2) * quantity
      );
      setActiveExtra.classList.add("Active");
    } else {
      var add = true;

      for (var m = 0; m < selectedExtras.length; m++) {
        if (selectedExtras[m].extraName === extra.extraName) {
          //if it is not the same extra
          setItemFinalTotal(
            (
              parseFloat(itemFinalTotal) -
              parseFloat(selectedExtras[m].extraPrice) * quantity
            ).toFixed(2)
          );
          selectedExtras.splice(m, 1);
          add = false;
          setActiveExtra.classList.remove("Active");
        } else {
          add = true;
        }
      }
      if (add === true) {
        var sameType = 1;
        for (var z = 0; z < selectedExtras.length; z++) {
          if (extra.extraType === selectedExtras[z].extraType) {
            sameType = sameType + 1;
          }
        }
        var max = 1;
        for (var t = 0; t < menuItemExtras.length; t++) {
          if (menuItemExtras[t].code.extraCode === extra.extraCode) {
            max = menuItemExtras[t].extras[0].options[0].maxQtySelected;
          }
        }
        if (sameType > max) {
          toast.error(`Maximum of ${max} selections!`);
          return;
        }

        setItemFinalTotal(
          (
            parseFloat(extra.extraPrice) * quantity +
            parseFloat(itemFinalTotal)
          ).toFixed(2)
        );
        setActiveExtra.classList.add("Active");
        setSelectedExtras(selectedExtras.concat(extra));
      }
    }
  }

  async function handleSubmit() {
    if (menuItemExtras) {
      var reqNum = 0;
      for (var a = 0; a < menuItemExtras.length; a++) {
        if (menuItemExtras[a].extras[0].options[0].required >= 1) {
          reqNum = reqNum + menuItemExtras[a].extras[0].options[0].required;
        }
      }
      if (reqNum > selectedExtras.length) {
        toast.error("Make a selection for all required options");
        return;
      }
    }
    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false);
    }, 5000);

    specialRequests = document.getElementById("itemRequests").value;
    var itemGuid = CreateGuid();
    var selectedToppingsTotal = 0;

    for (var i = 0; i < selectedExtras.length; i++) {
      selectedToppingsTotal =
        selectedToppingsTotal + selectedExtras[i].extraPrice;
    }

    let userCartData = {};
    var orderType = null;
    var userGuid = CreateGuid();
    var newCart = {};
    var newUser = null;

    userCartData = {
      CartID: userGuid,
      OrderType: orderType,
      CartPrice: 0,
      Item: {
        CartItemID: itemGuid,
        ItemQuantity: quantity,
        CartItem_CartID: userGuid,
        ItemSpecialRequests: specialRequests,
        Extras: [],
      },
    };
    if (appContext.userInfo) {
      userCartData.CartID = appContext.userInfo.userID;
      userCartData.Item.CartItem_CartID = appContext.userInfo.userID;
    }
    if (selectedSize) {
      userCartData.Item.ItemPrice = selectedSize.itemPrice;
      userCartData.Item.ItemName = selectedSize.itemName;
      userCartData.Item.CartItem_MenuItemID = selectedSize.menuItemID;
      userCartData.Item.ItemCategory = selectedSize.itemCategory;
      userCartData.Item.ItemSubCategory = selectedSize.itemSubCategory;
      userCartData.Item.ItemDescription = selectedSize.itemDescription;
      userCartData.Item.MenuID = selectedSize.menuID;
      userCartData.Item.SizeCode = selectedSize.itemSize;
      userCartData.Item.ItemOptions = selectedSize.itemOptions;
      userCartData.Item.OrderType = selectedSize.orderType;
      userCartData.Item.OrderDay = selectedSize.orderDay;
      userCartData.Item.OrderTime = selectedSize.orderTime;
      userCartData.Item.PayInStore = selectedSize.payInStore;
    } else {
      userCartData.Item.ItemPrice = props.selectedItem.itemPrice;
      userCartData.Item.ItemName = props.selectedItem.itemName;
      userCartData.Item.CartItem_MenuItemID = props.selectedItem.menuItemID;
      userCartData.Item.ItemCategory = props.selectedItem.itemCategory;
      userCartData.Item.ItemSubCategory = props.selectedItem.itemSubCategory;
      userCartData.Item.ItemDescription = props.selectedItem.itemDescription;
      userCartData.Item.MenuID = props.selectedItem.menuID;
      userCartData.Item.ItemOptions = props.selectedItem.itemOptions;
      userCartData.Item.SizeCode = null;
      userCartData.Item.OrderType = props.selectedItem.orderType;
      userCartData.Item.OrderDay = props.selectedItem.orderDay;
      userCartData.Item.OrderTime = props.selectedItem.orderTime;
      userCartData.Item.PayInStore = props.selectedItem.payInStore;

      if (props.selectedItem.itemSize) {
        userCartData.Item.SizeCode = props.selectedItem.itemSize;
      }
    }
    userCartData.Item.RewardCost = 0;

    var extrasTotal = 0.0;
    var formattedExtras = [];
    for (var p = 0; p < selectedExtras.length; p++) {
      extrasTotal = extrasTotal + selectedExtras[p].extraPrice;
      selectedExtras[p].extras = null;
      selectedExtras[p].cartExtra_CartID = userGuid;
      selectedExtras[p].cartExtra_CartItemID = itemGuid;
      if (selectedExtras[p].extraDescription === "FirstHalf") {
        selectedExtras[p].pizzaHalfCode = "(1st Half)";
      } else if (selectedExtras[p].extraDescription === "SecondHalf") {
        selectedExtras[p].pizzaHalfCode = "(2nd Half)";
      } else {
        selectedExtras[p].pizzaHalfCode = null;
      }
      formattedExtras[p] = {
        Active: selectedExtras[p].active,
        CartExtra_CartID: selectedExtras[p].cartExtra_CartID,
        CartExtra_CartItemID: selectedExtras[p].cartExtra_CartItemID,
        ExtraCode: selectedExtras[p].extraCode,
        ExtraDescription: selectedExtras[p].extraDescription,
        ExtraName: selectedExtras[p].extraName,
        ExtraPrice: selectedExtras[p].extraPrice,
        ExtraSize: selectedExtras[p].extraSize,
        ExtraType: selectedExtras[p].extraType,
        Extras: selectedExtras[p].extras,
        CartExtra_MenuItemExtraID: selectedExtras[p].menuItemExtraID,
        PizzaHalfCode: selectedExtras[p].pizzaHalfCode,
      };
      userCartData.Item.Extras.push(formattedExtras[p]);
    }
    var totalPrice = 0.0;
    if (selectedSize) {
      totalPrice = (selectedSize.itemPrice + extrasTotal) * quantity;
    } else {
      totalPrice = (props.selectedItem.itemPrice + extrasTotal) * quantity;
    }
    let numDouble = totalPrice.toFixed(2);
    userCartData.Item.ItemTotalPrice = parseFloat(numDouble);

    var newAppContext;
    //if user is not signed in yet
    //create guest user and set context
    if (!appContext.userInfo) {
      //create new cart for context
      if (!appContext.cart || !appContext.cart.orderType) {
        newCart = {
          CartID: userGuid,
          OrderType: null,
          CartPrice: userCartData.Item.ItemTotalPrice,
        };
      } else {
        newCart = {
          CartID: userGuid,
          OrderType: appContext.cart.orderType,
          CartPrice: userCartData.Item.ItemTotalPrice,
        };
      }
      const response = await axios.post(
        `${BASE_API}api/User/GetGuestToken`,
        userGuid,
        {
          headers: {
            "Content-Type": "application/json",
            Application: APPLICATION,
          },
        }
      );
      newUser = {
        userID: userGuid,
        token: response.data.token,
        role: "Guest",
        storeName: STORE_NAME_CODE,
      };

      const newItem = userCartData.Item;
      newAppContext = {
        userInfo: newUser,
        cart: newCart,
        cartItems: [newItem],
      };
      props.setAppContext(newAppContext);
      localStorage.setItem("appContext", JSON.stringify(newAppContext));
    } else {
      var newItem = userCartData.Item;

      //check if exact item exists
      var itemExists = checkIfItemExistsInCart(appContext.cartItems, newItem);

      //check if itemExists is true and replace item with Same === true
      var cartItems = itemExists.itemExists
        ? itemExists.cartItems.map((item) =>
          item.Same === true ? newItem : item
        )
        : itemExists.cartItems
          ? [...itemExists.cartItems, newItem]
          : [newItem];

      var deleteItemId = 0;
      var newPrice = 0;
      if (itemExists.itemExists === true) {
        for (var x = 0; x < itemExists.cartItems.length; x++) {
          if (itemExists.cartItems[x].Same === true) {
            userCartData.Item.ItemQuantity =
              quantity + itemExists.cartItems[x].ItemQuantity;
            userCartData.Item.ItemTotalPrice =
              userCartData.Item.ItemTotalPrice +
              itemExists.cartItems[x].ItemTotalPrice;
            deleteItemId = itemExists.cartItems[x].CartItemID;
          } else {
            newPrice = newPrice + itemExists.cartItems[x].ItemTotalPrice;
          }
        }
        newItem = userCartData.Item;

        if (
          appContext.userInfo.role === "User" ||
          appContext.userInfo.role === "Admin"
        ) {
          try {
            await axios.delete(
              `${BASE_API}api/Cart/DeleteCartItem?cartitemId=` +
              deleteItemId +
              "&deleteTotal=" +
              newPrice,
              {
                headers: {
                  Authorization: `Bearer ${appContext.userInfo.token}`,
                  Application: APPLICATION,
                },
              }
            );
          } catch (error) {
            if (error.response.status === 401) {
              newAppContext = {
                userInfo: null,
                cart: null,
                cartItems: null,
              };
              props.setAppContext(newAppContext);
              localStorage.setItem("appContext", JSON.stringify(newAppContext));
            }
            toast.error("Your session expired. Please try to login again");
          }
        }
      }

      if (
        appContext.userInfo.role === "User" ||
        appContext.userInfo.role === "Admin"
      ) {
        var menuItemID;
        if (selectedSize) {
          menuItemID = selectedSize.menuItemID;
        } else {
          menuItemID = props.selectedItem.menuItemID;
        }
        try {
          await axios.post(
            `${BASE_API}api/Cart/AddToCart/${menuItemID}`,
            userCartData,
            {
              headers: {
                Authorization: `Bearer ${appContext.userInfo.token}`,
                Application: APPLICATION,
              },
            }
          );
          //update cart with newItem and place in updatedCart
          const updatedCart = updateCartValues(appContext, newItem, itemExists);

          newAppContext = {
            userInfo: appContext.userInfo,
            cart: updatedCart,
            cartItems: cartItems,
          };
          props.setAppContext(newAppContext);
          localStorage.setItem("appContext", JSON.stringify(newAppContext));
        } catch (error) {
          if (error.response.status === 401) {
            newAppContext = {
              userInfo: null,
              cart: null,
              cartItems: null,
            };
            props.setAppContext(newAppContext);
            localStorage.setItem("appContext", JSON.stringify(newAppContext));
          }
          toast.error(error);
        }
      } else if (
        appContext.userInfo.role === "Guest" ||
        appContext.userInfo.role === "GuestUser"
      ) {
        try {
          //update cart with newItem and place in updatedCart
          const updatedCart = updateCartValues(appContext, newItem, itemExists);

          newAppContext = {
            userInfo: appContext.userInfo,
            cart: updatedCart,
            cartItems: cartItems,
          };
          props.setAppContext(newAppContext);
          localStorage.setItem("appContext", JSON.stringify(newAppContext));
        } catch (error) {
          if (error.response.status === 401) {
            newAppContext = {
              userInfo: null,
              cart: null,
              cartItems: null,
            };
            props.setAppContext(newAppContext);
            localStorage.setItem("appContext", JSON.stringify(newAppContext));
          }
          toast.error(error);
        }
      }
    }

    props.setShowItemModal(false);
  }

  async function closeModal() {
    for (var i = 0; i < sameNameItems.length; i++) {
      sameNameItems[i].active = null;
    }
    props.setShowItemModal(false);
  }

  return (
    <>
      <Modal className="itemModal" show={props.showItemModal}>
        <Modal.Body>
          <Modal.Title>
            <h2 className="fs-3 m-0 fw-bold">Customize Your Item!</h2>
            <FaWindowClose
              onClick={closeModal}
              style={{ width: "1.5em", height: "1.5em" }}
              className="text-danger"
            />
          </Modal.Title>

          <div className="itemModal_body">
            <h2 className="itemModal_name fw-bold w-100 mb-1">
              {props.selectedItem.itemName}
            </h2>

            {props.selectedItem.itemDescription && (
              <div className="w-100 mb-1 description">
                <em>{props.selectedItem.itemDescription}</em>
              </div>
            )}

            <div className="itemModal_quantity p-2 d-flex align-items-center justify-content-between w-100 mb-1 bg-light border border-dark rounded">
              <h4 className="quantity">Quantity:</h4>
              <div className="d-flex align-items-center">
                <div className="sub" onClick={subtractQuantity}>
                  <AiFillMinusCircle
                    style={{ width: "1.75em", height: "1.75em" }}
                  />
                </div>
                <div className="current_number mx-3 fw-bold text-danger">
                  {quantity}
                </div>
                <div className="add" onClick={addQuantity}>
                  <AiFillPlusCircle
                    style={{ width: "1.75em", height: "1.75em" }}
                  />
                </div>
              </div>
            </div>

            {sameNameItems.length > 1 && (
              <div className="itemModal_optionsSection">
                <div
                  className="itemModal_header d-flex justify-content-between align-items-center rounded"
                  onClick={() => toggleSection("sameNameItems")}
                  style={{ cursor: "pointer" }}
                >
                  <h5 className="header mb-0">
                    Size - {selectedSize.itemSize}
                  </h5>
                  <span className="expand_collapse_button">
                    {expandedSections.sameNameItems ? (
                      <FaChevronCircleDown />
                    ) : (
                      <FaChevronCircleUp />
                    )}
                  </span>
                </div>

                {expandedSections.sameNameItems && (
                  <div className="itemModal_options w-100 my-1">
                    <div className="itemModal_sizes d-flex flex-column w-100">
                      {sameNameItems?.map((i) => (
                        <div
                          id={`${i.itemSize}Size`}
                          className={`size d-flex justify-content-between w-100 mb-1 bg-light border border-dark rounded options ${i.active}`}
                          key={i.menuItemID}
                          onClick={() => selectSize(i)}
                          style={{ padding: "0.4rem 0.5rem" }}
                        >
                          <div className="fs-6">{i.itemSize}</div>
                          <div className="fs-6 fw-bold">
                            {"$" + i.itemPrice.toFixed(2)}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}

            {menuItemExtras?.map((i, index) => (
              <>
                {i.code.required === 1 && i.extras.length === 1 && i.extras[0].options[0].maxQtySelected === 1 ? (
                  <>
                    <div 
                      className="pre_selected w-100 d-flex justify-content-between align-items-center rounded" 
                      id={`${i.extras[0].options[0].extraName.replace(/\s/g, "")}${i.extras[0].options[0].extraDescription}`}
                    >
                      {i.extras[0].name}
                    </div>
                  </>
                ) : (
                  <>
                    <div key={index} className="itemModal_optionsSection">
                      <div
                        className={`itemModal_header d-flex justify-content-between align-items-center rounded ${expandedSections[index] ? "no-bottom-radius" : ""
                          }`}
                        onClick={() => toggleSection(index)}
                        style={{ cursor: "pointer" }}
                      >
                        <h5 className="header mb-0">{i.code.extraCode}</h5>
                        <div className="d-flex">
                          {i.extras[0].options[0].required > 0 && (
                            <h5 className="required_tab mb-0">
                              {i.code.maxQty > 1 ? (
                                <>
                                  Required (Max:
                                  <span>
                                    <b>{i.code.maxQty})</b>
                                  </span>
                                </>
                              ) : (
                                <>Required</>
                              )}
                            </h5>
                          )}
                          <span className="expand_collapse_button">
                            {expandedSections[index] ? (
                              <FaChevronCircleDown />
                            ) : (
                              <FaChevronCircleUp />
                            )}
                          </span>
                        </div>
                      </div>

                      {expandedSections[index] && (
                        <div className="itemModal_options w-100 my-1">
                          {i.extras?.map((j, index2) => (
                            <div
                              key={index2}
                              className="extraOptions d-flex justify-content-between w-100 mb-1 bg-light rounded"
                            >
                              {j.options.length > 1 ? (
                                <>
                                  {/* if opions are > 1 than it means IT IS a pizza (MULTIPLE OPTIONS OF SAME EXTRA FOR PIZZA TOPPINGS) */}
                                  <div className="option_choice_pizza d-flex w-100 justify-content-between">
                                    <div
                                      className="fs-6 px-2 py-2"
                                      id={`${j.options[0].extraName.replace(
                                        /\s/g,
                                        ""
                                      )}_selected`}
                                    >
                                      {j.name}
                                    </div>

                                    {j.options.length > 1 && (
                                      <div className="options d-flex px-1 py-1">
                                        {j.options?.map((k, index3) => (
                                          <div
                                            key={index3}
                                            id={`${k.extraName.replace(/\s/g, "")}${k.extraDescription
                                              }`}
                                            className={`mx-1 ${k.extraDescription
                                              } ${k.extraName.replace(/\s/g, "")}`}
                                            onClick={() => selectToppings(k)}
                                          />
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                </>
                              ) : (
                                <>
                                  {/* if options are < 1 than it means its NOT a pizza */}
                                  {j.options[0].maxQtySelected > 1 ? (
                                    <>
                                      <div
                                        id={`${j.options[0].extraName.replace(
                                          /\s/g,
                                          ""
                                        )}${j.options[0].extraDescription}`}
                                        className={`option_choice d-flex justify-content-between w-100 h-100 fs-6 px-2 ${j.options[0].extraType.replace(
                                          /\s/g,
                                          ""
                                        )}`}
                                        onClick={() => addExtra(j.options[0])}
                                      >
                                        <div>{j.name}</div>
                                        {j.options[0].extraPrice > 0 ? (
                                          <div className="fw-bold">
                                            ${j.options[0].extraPrice.toFixed(2)}
                                          </div>
                                        ) : null}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        id={`${j.options[0].extraName.replace(
                                          /\s/g,
                                          ""
                                        )}${j.options[0].extraDescription}`}
                                        className={`option_choice d-flex justify-content-between w-100 h-100 fs-6 px-2 ${j.options[0].extraType.replace(
                                          /\s/g,
                                          ""
                                        )}`}
                                        onClick={() => selectExtra(j.options[0])}
                                      >
                                        <div>{j.name}</div>
                                        {j.options[0].extraPrice > 0 ? (
                                          <div className="fw-bold">
                                            ${j.options[0].extraPrice.toFixed(2)}
                                          </div>
                                        ) : null}
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </>
            ))}

            <SpecialRequest />
          </div>

          <div
            className="itemModal_Submit"
            onClick={!isDisabled ? () => handleSubmit() : undefined}
          >
            {isDisabled ? (
              <>
                <div className="itemModal_totalPrice">
                  Total Price: ${itemFinalTotal}
                </div>
                <button>
                  <Loading />
                </button>
              </>
            ) : (
              <>
                <div className="itemModal_totalPrice">
                  Total Price: ${itemFinalTotal}
                </div>
                <button>ADD ITEM TO CART</button>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ItemModal;
